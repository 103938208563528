<template>
  <v-app>
    <v-banner
      single-line
      class="banner"
    >
      <v-carousel class="h5-carousel-new carousel-banner" height="100%"
                  :show-arrows="false" :cycle="true"
                  hide-delimiter-background hide-delimiters>
        <v-carousel-item v-for="(v,index) in bannerList" :key="index">
          <v-img :src="v" width="100%" contain>
            <template v-slot:placeholder><img-placeholder></img-placeholder></template>
          </v-img>
        </v-carousel-item>
      </v-carousel> 
    </v-banner>
    <!-- 产品矩阵 -->
    <v-card flat class="width-per-100 pb-6">
      <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0 mt-6 line-height-28">产品矩阵</v-card-title>
      <div class="mt-4 px-3">
        <v-row v-for="(row,rowIndex) in products" :key="rowIndex">
          <v-col col="6" class="d-flex justify-center"
                 style="padding: 0 5px;"
                 v-for="(col,colIndex) in row" :key="colIndex">
            <v-card flat class="pa-3 h5-card"
                    :to="col.router"
                    :min-height="$vuetify.breakpoint.name === 'xs'?95:78"
                    width="100%">
              <v-card-title class="pa-0 font-size-16 line-height-22">
                <v-avatar size="32" class="mr-1">
                  <v-img :src="col.icon"></v-img>
                </v-avatar>
                {{col.title}}
              </v-card-title>
              <v-card-text class="pa-0 mt-1 ml-1" style="line-height: 17px">
                <span class="font-size-12 color-666 font-weight-300">{{col.explain}}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- 行业解决方案 -->
    <v-card color="#2181EA" flat class="py-6 position-r" style="border-radius: unset">
      <v-card-title class="justify-center font-weight-bold font-size-20 color-FFF pa-0 line-height-28">行业解决方案</v-card-title>
      <v-tabs v-model="tab" background-color="transparent" color="white" dark
              class="xs-tab">
        <v-tab v-for="(v, k) in industrySolutions" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === industrySolutions.length - 1 ? 'mr-5':'']"
               style="min-width: unset">
          <b class="color-FFF font-size-14 letter-spacing line-height-20">{{ v.tabTitle }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-5" v-model="tab" style="border-radius: 10px;">
        <v-tab-item v-for="(v, k) in industrySolutions" :key="k" :transition="false">
          <v-card flat color="white" class="pa-5">
            <v-img :src="v.img" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
            <v-card-title class="pa-0 mt-4 font-size-18 font-weight-bold line-height-25">{{v.lunTitle}}</v-card-title>
            <v-card-text class="pa-0 font-size-15 color-555 line-height-21" style="margin-top: 6px;" v-text="v.tip" />
            <div class="font-size-13 color-666" style="margin-top: 14px;">
              <v-card-text class="pa-0 line-height-26" v-for="(i, j) in v.text" :key="j" v-text="i" />
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <div class="tab-btn-prev" @click.stop="handleTabBtn('prev')"></div>
      <div class="tab-btn-next" @click.stop="handleTabBtn('next')"></div>
    </v-card>
    <!-- 核心优势 -->
    <v-card class="px-4 py-6" flat>
      <v-card-title class="justify-center font-weight-bold font-size-20 pa-0 line-height-28">核心优势</v-card-title>
      <div class="mt-4">
        <v-row v-for="(row,rowIndex) in advantageList" :key="rowIndex">
          <v-card class="pa-3 d-flex h5-card" min-height="90" width="100%" flat>
            <v-img :src="row.icon" max-width="65" contain position="top"></v-img>
            <div class="ml-4">
              <v-card-title class="pa-0 mt-1 font-size-16 font-weight-bold line-height-20">
                {{row.title}}
              </v-card-title>
              <v-card-text class="pa-0 mt-2 font-size-13 color-666 line-height-20">
                {{row.explain}}
              </v-card-text>
            </div>
          </v-card>
        </v-row>
      </div>
    </v-card>
    <v-card class="py-5 px-2" flat color="#F5F8FC">
      <v-card-title class="justify-center font-weight-bold font-size-20 pa-0 line-height-28">合作伙伴</v-card-title>
      <v-img class="mt-3" width="100%" contain src="https://h5.ophyer.cn/official_website/other/h5Home05.png">
        <template v-slot:placeholder><img-placeholder></img-placeholder></template>
      </v-img>
    </v-card>
  </v-app>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      bannerList: ["https://h5.ophyer.cn/official_website/banner/h5Home-banner01.png"],
      products: [
          [{
            title: "万目云",
            icon: require("../../../public/img/icons/menu/wanmuyun.png"),
            router: "/wmyH5/wmyH5Home",
            explain: "赋能商业的智慧营销平台",
            isNewWindow: true
          },{
            title: "万景云",
            icon: require("../../../public/img/icons/menu/wanjingyun.png"),
            router: "/h5/productsH5/SceneCloud",
            explain: "专注拓展文旅新商机"
          }],[{
            title: "万学云",
            icon: require("../../../public/img/icons/menu/wanxueyun.png"),
            router: "/h5/productsH5/LearnCloud",
            explain: "一站式教学、运营、管理"
          },{
            title: "万效云",
            icon: require("../../../public/img/icons/menu/wanxiaoyun.png"),
            router: "/h5/productsH5/EfficiencyCloud",
            explain: "数字化场景营销平台"
          }],[{
            title: "万界云",
            icon: require("../../../public/img/icons/menu/wanjieyun.png"),
            router: "/h5/productsH5/SaasCloud",
            explain: "大客户定制化营销服务"
          },{
            title: "万擎云",
            icon: require("../../../public/img/icons/menu/wanqingyun.png"),
            router: "/h5/productsH5/WanQingCloud",
            explain: "多功能3D在线编辑器"
          }]
      ],
      industrySolutions: [
        {
          tabTitle: '电商零售',
          img: 'https://h5.ophyer.cn/official_website/other/home-tab-img01-1.png',
          lunTitle: '数字场景构建线上沉浸式购物体验',
          tip: '强交互，重体验，为业务增长助力',
          text: [
            '• 720°可交互场景，轻松体验虚拟购物',
            '• 360环物展示更多商品细节',
            '• 热点、虚拟导购等特色功能，适配更多营销场景',
            '• 优惠券、转盘等营销玩法，提升GMV及销售额'
          ]
        },
        {
          tabTitle: '房产家居',
          img: 'https://h5.ophyer.cn/official_website/other/home-tab-img02-1.png',
          lunTitle: '房产数字化服务，打破传统看房体验',
          tip: '沉浸式体验，助力潜客转化',
          text: [
            '• VR线上带看，实时在线互动，看房更便捷',
            '• VR场景购物，看房购物两不误',
            '• 精密3D扫描，100%还原三维实景',
          ]
        },
        {
          tabTitle: '智慧文旅',
          img: 'https://h5.ophyer.cn/official_website/other/home-tab-img03-1.png',
          lunTitle: '虚拟现实技术加持，推动文旅数字化转型',
          tip: '科技赋能创意，让景区焕发全新活力',
          text: [
            '• AR打卡互动游戏，为景区商家引流，促进二消',
            '• VR虚拟展厅，打造爆款科技虚拟展厅',
            '• 数字景区，搭建完成的科技+文化场景',
          ]
        },
        {
          tabTitle: '智慧教育',
          img: 'https://h5.ophyer.cn/official_website/other/home-tab-img04-1.png',
          lunTitle: '科技与教育结合，助力教育信息化管理',
          tip: '软硬件结合，创新教育体验',
          text: [
            '• 以AR/VR技术，打造沉浸式、互动教学新体验',
            '• 虚拟现实与硬件结合，打造XR未来教室',
            '•  虚拟仿真实验室，赋能培训行业',
          ]
        },
        {
          tabTitle: '汽车出行',
          img: 'https://h5.ophyer.cn/official_website/other/home-tab-img05-1.png',
          lunTitle: '3D模型+VR看车，线上看车新体验',
          tip: '高精度建模，720°全景看车，更多细节表现',
          text: [
            '• 720°看外观，看内饰，线上体验实景看车',
            '•  线上留咨表单，为门店强势引流',
          ]
        }
      ],
      advantageList: [
        {
          title: "海量模板",
          icon: "https://h5.ophyer.cn/official_website/other/h5Home01.png",
          explain: "海量AR/VR模板一键套用，轻松生成营销内容",
        },
        {
          title: "资源整合",
          icon: "https://h5.ophyer.cn/official_website/other/h5Home02.png",
          explain: "丰富的营销运营经验，整合头部渠道资源，助力品牌快速落地营销策略",
        },
        {
          title: "技术保障",
          icon: "https://h5.ophyer.cn/official_website/other/h5Home03.png",
          explain: "专注AR/VR/AI行业数十年技术沉淀；多项专利技术",
        },
        {
          title: "完善服务",
          icon: "https://h5.ophyer.cn/official_website/other/h5Home04.png",
          explain: "7x24小时五星售后服务，随时随地相应，解除您的后顾之忧",
        }
      ],
      tab: 0,
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    handleTabBtn: function (type){
      const maxTab = this.industrySolutions.length - 1;
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    margin-top: -100px;
    .banner-text {
      p:nth-child(1) {
        font-size: 82px;
        //font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #fff;
        line-height: 88px;
      }
      p:nth-child(2) {
        margin-top: 25px;
        font-size: 24px;
        font-weight: 100 !important;
        color: #fff;
      }
      .banner-btn {
        margin-top: 90px;
        width: 200px;
        height: 60px;
        background: #0568FD;
        border-radius: 35px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  @media (min-width: 960px) {
    .container {
      max-width: 1200px !important;
    }
  }
  
}
</style>
